<template>
  <div>
    <div class="top_div" @click="goBack">
      <i class="el-icon-arrow-left left_link"></i>测评
    </div>
    <div>
      <yinsi
        v-if="current_step == 'yinsi'"
        @cancel="yinsi_cancel"
        @continue="yinsi_continue"
      />
      <step v-if="current_step == 'step'" @confirm="step_confirm" />
      <notice
        v-if="current_step == 'notice'"
        :konwinfo="need_konw"
        @confirm="notice_confirm"
      />
    </div>
  </div>
</template>

<script>
import yinsi from "./components/yinsi";
import step from "./components/step";
import notice from "./components/notice";
import { getDetail } from "@/api/wenjuan";
export default {
  name: "Inform",
  components: {
    yinsi,
    step,
    notice,
  },
  data() {
    return {
      uid: null,
      type: null,
      wenjuan_id: null,
      current_step: "yinsi", //步骤
      need_konw: "", //答题前须知
    };
  },
  async mounted() {
    //没有uid就进去选择病人
    if (this.$route.query.uid) {
      this.uid = this.$route.query.uid;
    } else {
      await this.go_choose_patient();
    }
    //没有问卷id就去选择对应的问卷
    if (this.$route.query.wenjuan_id) {
      this.wenjuan_id = this.$route.query.wenjuan_id;
    } else {
      await this.go_index();
    }
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
      await this.fetchDetail();
    }
  },
  methods: {
    //隐私取消步骤
    yinsi_cancel() {
      this.go_index();
    },
    //隐私继续下一步
    yinsi_continue() {
      this.current_step = "step";
    },
    //步骤提示，确认下一步
    step_confirm() {
      this.current_step = "notice";
    },
    //测评须知，确认下一步
    notice_confirm() {
      //根部不同条件跳转至不同页面
      let path;
      if (this.type === "vas") {
        path = "vas";
      } else if (this.type === "scl90") {
        path = "group_ans";
      } else {
        path = "single_ans";
      }
      //跳转到答题页面
      this.$router.push({
        path,
        query: {
          uid: this.uid,
          type: this.type,
          wenjuan_id: this.wenjuan_id,
        },
      });
    },
    //跳转至选择病人页面
    go_choose_patient() {
      this.$router.push({
        path: "choose_patient",
      });
    },
    //跳转至选择问卷页面
    go_index() {
      this.$router.push({
        path: "/",
        query: { uid: this.uid },
      });
    },
    //点击顶部的返回按钮
    goBack() {
      if (this.current_step == "yinsi") {
        this.go_index();
      } else if (this.current_step == "step") {
        this.current_step = "yinsi";
      } else if (this.current_step == "notice") {
        this.current_step = "step";
      }
    },
    //获取数据详情
    async fetchDetail() {
      const { data } = await getDetail(this.wenjuan_id);
      this.need_konw = data.need_konw;
    },
  },
};
</script>

<style lang="scss" scoped>
.top_div {
  display: flex;
  align-items: center;
  width: 100%;
  height: 131px;
  font-size: 48px;
  font-weight: bold;
  color: #101010;
  background-color: #fff;
}
.left_link {
  margin-left: 15px;
}
</style>
