<template>
  <div>
    <div class="card">
      <div class="title">第一步：测评须知</div>
      <div class="content">
        {{ konwinfo }}
      </div>
      <div class="know" @click="know">知道了</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Notice",
  props: {
    konwinfo: {
      type: String,
      default: "",
    },
  },
  methods: {
    know() {
      this.$emit("confirm");
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 638px;
  height: 1040px;
  padding-right: 77px;
  padding-left: 77px;
  margin: 0px auto;
  margin-top: 58px;
  text-align: center;
  background: #ffffff;
  border-radius: 50px;
  box-shadow: 0px 0px 18px 0px rgba(7, 133, 153, 0.06);
}
.title {
  padding-top: 84px;
  font-size: 48px;
  font-weight: 400;
  line-height: 54px;
  color: #333333;
}
.content {
  margin-top: 88px;
  font-size: 46px;
  font-weight: 400;
  line-height: 68px;
  color: #606266;
  text-align: left;
}
.know {
  width: 420px;
  height: 102px;
  margin: 0px auto;
  margin-top: 80px;
  font-size: 36px;
  font-weight: 400;
  line-height: 102px;
  color: #ffffff;
  cursor: pointer;
  background: #078599;
  border-radius: 51px;
}
</style>
